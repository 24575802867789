import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import remark from 'remark';
import remarkHTML from 'remark-html';
import 'swiper/css/swiper.css';
import interpolate from 'interpolate-range';

const Wrapper = styled.div`
  height: calc(100vh - 155px);
  min-height: 600px;
  flex-wrap: wrap;
  position: relative;
  display: none;

  @media (min-width: 700px) {
    display: flex;
  }
`;

const ProjectsContainer = styled.div`
  width: 100%;
  text-align: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Project = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ProjectTitle = styled.a`
  font-size: 64px;
  line-height: 1.1;
  font-weight: normal;
  /* display: inline-block; */
  display: block;
  position: relative;
  margin: 0;
  will-change: opacity, pointer-events;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
  transition: opacity 0.15s ease-in-out;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  /* &:after {
    content: ${({ cat }) => `"` + cat + `"`};
    position: absolute;
    right: 105%;
    width: 100%;
    text-align: right;
    bottom: 4px;
    font-size: 18px;
    line-height: 26px;
    will-change: transform, opacity;
    opacity: ${({ active }) => (active ? 1 : 0)};
    transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(-8%)')};
    transition: transform 0.1s ease-in-out, opacity 0.15s ease-in-out;
  } */

  /* &:before {
    content: '↗';
    position: absolute;
    left: 105%;
    text-align: left;
    bottom: 8px;
    font-size: 18px;
    line-height: 26px;
    border-bottom: 1px solid black;
    will-change: opacity;
    opacity: ${({ active }) => (active ? 1 : 0)};
    pointer-events: none;
  }

  &:hover {
    &:after {
      opacity: 1;
      transform: translateX(0);
    }

    &:before {
      opacity: 1;
    }
  } */
`;

const Category = styled.div`
  position: absolute;
  right: 100%;
  width: 100%;
  text-align: right;
  bottom: 4px;
  font-size: 18px;
  line-height: 26px;
  will-change: transform, opacity;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(-8%)')};
  transition: transform 0.1s ease-in-out, opacity 0.15s ease-in-out;
  padding-right: 10px;

  ${ProjectTitle}:hover & {
    opacity: 1;
    transform: translateX(0);
  }
`;

const ExitIcon = styled.div`
  position: absolute;
  left: 100%;
  text-align: left;
  bottom: 8px;
  font-size: 18px;
  line-height: 26px;
  border-bottom: 1px solid black;
  will-change: opacity;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: none;
  margin-left: 10px;

  ${ProjectTitle}:hover & {
    opacity: 1;
  }
`;

const Slider = styled.div`
  position: absolute;
  width: 122vh;
  height: 55vh;
  perspective: 73vh;
  margin: 0 auto;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: opacity;
  opacity: ${({ hide }) => (hide ? 0.6 : 1)};
  transition: opacity 0.15s ease-in-out;
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  will-change: transform;

  & > * {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const Slide = styled.div`
  height: 55vh;
  padding: 0 120px;
  transition: opacity 0.3s ease-in-out;
`;

const Video = styled.video`
  height: auto;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: calc(50% + 37vh);
`;

const Description = styled.div`
  position: absolute;
  width: 100%;
  font-size: 18px;
  text-align: center;
  will-change: opacity;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
  transition: opacity 0.15s ease-in-out;
  transform: translateY(-50%);
`;

const Obracak = ({ projects, sticky }) => {
  const sliderEl = useRef(null);
  const sliderWrapEl = useRef(null);
  const videoEls = useRef([]);
  const step = useRef(360 / 3);
  const myR = useRef(1000);
  const sX = useRef(null);
  const nX = useRef(null);
  const desX = useRef(0);
  const tX = useRef(90);
  const scrollY = useRef(0);
  const int = useRef(null);
  const [visibleProject, setVisibleProject] = useState(null);
  const [showPoster, setShowPoster] = useState(false);

  useEffect(() => {
    int.current = interpolate({
      inputRange: [window.innerHeight, window.innerHeight * 8],
      outputRange: [0, 360],
      // clamp: true,
    });

    if (sliderEl.current) {
      const all = 8;
      const gCS = typeof window !== 'undefined' && window.getComputedStyle(sliderEl.current);
      const width = parseInt(gCS.width);
      myR.current = width / (2 * Math.tan(Math.PI / all));
      step.current = 360 / all;

      for (let i = 0; i < all; i++) {
        const rad = (i * step.current * Math.PI) / 180;
        if (sliderWrapEl.current.children[i]) {
          sliderWrapEl.current.children[i].style.transform = `
          translate3d(${myR.current * Math.sin(rad).toFixed(4)}px,
          0,${myR.current * Math.cos(rad).toFixed(4)}px)
          rotate3d(0, 1, 0, ${i * step.current}deg)`;
        }
      }
    }
    // applyTranform()
    // console.log('apply')

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sliderEl.current]);

  const applyTranform = (el) => {
    el.style.transform = `translate3d(0, 0, ${-myR.current}px) rotate3d(0, 1, 0, ${tX.current}deg)`;
  };

  useEffect(() => {
    if (sliderWrapEl.current) {
      applyTranform(sliderWrapEl.current);
    }
  }, [sliderWrapEl.current]);

  const handleScroll = () => {
    if (sliderWrapEl.current && window.scrollY > window.innerHeight && window.scrollY < window.innerHeight * 12) {
      nX.current = int.current(window.scrollY);

      desX.current = nX.current - sX.current;

      tX.current -= desX.current;

      applyTranform(sliderWrapEl.current);

      if (tX.current < -203) {
        setVisibleProject(null);
      } else if (tX.current < -157) {
        setVisibleProject(4);
      } else if (tX.current < -112) {
        setVisibleProject(3);
      } else if (tX.current < -67) {
        setVisibleProject(2);
      } else if (tX.current < -22) {
        setVisibleProject(1);
      } else if (tX.current < 20) {
        setVisibleProject(0);
      } else {
        setVisibleProject(null);
      }

      sX.current = nX.current;
    }

    scrollY.current = window.scrollY;
  };

  useEffect(() => {
    setShowPoster(true);

    // videoEls.current[0].load()
    // videoEls.current[0].play()

    videoEls.current &&
      videoEls.current.forEach((el) => {
        el.load();
        el.play();
      });

    return () => {
      videoEls.current &&
        videoEls.current.forEach((el) => {
          el.pause();
        });
    };
  }, []);

  // useEffect(() => {
  //   if (!!videoEls.current[visibleProject]) {
  //     videoEls.current[visibleProject].play();
  //   }
  // }, [visibleProject]);

  return (
    <Wrapper sticky={sticky}>
      <ProjectsContainer>
        {projects.map((item, i) => {
          return (
            <Project key={item.title}>
              <ProjectTitle
                href={item.url}
                target='_blank'
                cat={item.category}
                hide={i !== visibleProject && visibleProject !== null}
                active={i === visibleProject}

                // className={`${i === visibleProject ? 'active' : ''} ${
                //   (i !== visibleProject && visibleProject !== null) ? 'hide' : ''
                // }`}
              >
                <Category hide={i !== visibleProject && visibleProject !== null} active={i === visibleProject}>{item.category}</Category>

                {item.title}

                <ExitIcon active={i === visibleProject}>↗</ExitIcon>
              </ProjectTitle>
            </Project>
          );
        })}
      </ProjectsContainer>

      <Slider ref={sliderEl} hide={visibleProject === null}>
        <SliderWrapper ref={sliderWrapEl}>
          {projects.map((item, i) => {
            return (
              <Slide key={i}>
                {
                  <Video
                    ref={(el) => (videoEls.current[i] = el)}
                    loop
                    muted
                    defaultMuted
                    playsInline
                    preload='none'
                    poster={item.poster_dt.publicURL}
                  >
                    <source src={showPoster ? item.video_dt_webm.publicURL : undefined} type='video/webm' />
                    <source src={showPoster ? item.video_dt_mp4.publicURL : undefined} type='video/mp4' />
                  </Video>
                }
              </Slide>
            );
          })}
        </SliderWrapper>
      </Slider>

      <DescriptionWrapper>
        {projects.map((item, i) => {
          return (
            <Description key={i} hide={i !== visibleProject}>
              <div
                dangerouslySetInnerHTML={{
                  __html: remark()
                    .use(remarkHTML)
                    .processSync(item.description)
                    .toString(),
                }}
              ></div>
            </Description>
          );
        })}
      </DescriptionWrapper>
    </Wrapper>
  );
};

export default Obracak;
