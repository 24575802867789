import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { connect } from 'react-redux';
import { toggleAlive, tick } from '../redux/actions';
import ejtyejty from '../helpers/ejtyejty';

const Wrapper = styled.section`
  position: relative;
  height: 100vh;
  width: 100%;
  opacity: ${({ opa }) => opa};
  transition: opacity 0.1s linear;
`;

const Table = styled.table`
  & td {
    border: 0px solid var(--background);
    height: 20px;
    width: 20px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    color: hotpink;
    background-color: transparent;
    padding: 0;
    border-radius: 50%;
    position: relative;

    @media (min-width: 700px) {
      padding: 10px;
      width: 50px;
      height: 50px;
    }

    &.alive,
    &.new-born {
      /* background-color: var(--color-8080); */
      /* background-color: var(--background);
      border: 2px solid var(--secondary); */
      /* background-color: transparent; */
      /* background-color: white; */
      &:after {
        height: 75%;
        width: 75%;
        /* content: '🟣'; */
        content: ' ';
        background-color: var(--color-8080);
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: -40%;

        @media (min-width: 700px) {
          height: 20px;
          width: 20px;
          top: 10px;
          left: 10px;
        }
        /* @media (min-width: 700px) { */
        /* font-size: 45px; */
        /* } */
      }
    }
  }
`;

const Cell = ({ alive, newBorn, handleClick, x, y }) => {
  return (
    <td
      // onClick={handleClick}
      onMouseOver={e => {
        handleClick(e);
      }}
      className={`${alive ? 'alive' : ''} ${newBorn ? 'new-born' : ''}`}
    ></td>
  );
};

const MemoizedCell = React.memo(Cell);

const BoardComp = ({ board, toggleAlive, isMobile }) => {
  useEffect(() => {
    ejtyejty(toggleAlive, isMobile);
  }, [toggleAlive, isMobile]);

  return (
    <div>
      <Table>
        <tbody>
          {board.map((row, i) => (
            <tr key={i}>
              {row.map((cell, j) => (
                <MemoizedCell
                  key={j}
                  x={j}
                  y={i}
                  alive={cell.status}
                  newBorn={cell.newBorn}
                  handleClick={() => toggleAlive(i, j)}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = ({ board }) => {
  return { board };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleAlive: (x, y) => dispatch(toggleAlive(x, y))
  };
};

const Board = connect(mapStateToProps, mapDispatchToProps)(BoardComp);

const GameOfLife = ({ tick, isMobile }) => {
  const [opa, setOpa] = useState(1);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = e => {
    const range = window.innerHeight / 3;
    const scrollTop = window.pageYOffset;
    const height = window.innerHeight;
    const offset = height / 2;
    const calc = 1 - (scrollTop - offset + range) / range;

    if (calc > '1') {
      setOpa(1);
    } else if (calc < '0') {
      setOpa(0);
    } else {
      setOpa(calc);
    }

    if (window.pageYOffset % 3 === 0) {
      tick();
    }
  };
  return (
    <Wrapper opa={opa}>
      <Board isMobile={isMobile} />
    </Wrapper>
  );
};

const mapStateToProps_2 = () => {
  return {};
};

const mapDispatchToProps_2 = dispatch => {
  return {
    tick: () => dispatch(tick())
  };
};

export default connect(mapStateToProps_2, mapDispatchToProps_2)(GameOfLife);
