import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import remark from 'remark';
import remarkHTML from 'remark-html';
import 'swiper/css/swiper.css';

const Wrapper = styled.div`
  min-height: calc(100vh - 105px);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  -ms-touch-action: pan-y;
  touch-action: pan-y;

  @media (min-width: 950px) {
    display: none;
  }
`;

const Slider = styled.div`
  width: 73.5vw;
  height: 142vw;
  perspective: 50vh;
  margin: 0 auto;
  z-index: -1;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.15s ease-in-out 0.5s;
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  will-change: transform;
  transition: transform 0.35s cubic-bezier(0.29, 0.16, 0.17, 1);

  & > * {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const Slide = styled.div`
  padding: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
`;

const Video = styled.video`
  height: 100%;
  width: auto;
  width: 100%;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  text-align: center;
  min-height: 130px;
  margin-top: 30px;
`;

const Description = styled.div`
  position: absolute;
  width: 100%;
  font-size: 18px;
  text-align: center;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.15s ease-in-out;
  padding: 0 20px;
  pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};

  p {
    display: inline-block;
    color: inherit;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const Title = styled.a`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: inherit;
  text-decoration: underline;
  display: block;
`;

const ObracakMobile = ({ projects }) => {
  const wrapperEl = useRef(null);
  const sliderEl = useRef(null);
  const videoEls = useRef([]);
  const sliderWrapEl = useRef(null);
  const angle = useRef(0);
  const step = useRef(360 / 10);
  const myR = useRef(100);
  const [visibleProject, setVisibleProject] = useState(null);
  const [lastVisibleProject, setLastVisibleProject] = useState(0);
  const [initialX, setInitialX] = useState(null);
  const [initialY, setInitialY] = useState(null);
  const [showPoster, setShowPoster] = useState(false);

  useEffect(() => {
    setShowPoster(true);
    // videoEls.current.map((item, i) => {
    //   item.children[0].src = projects[i].video_mob_mp4.publicURL;
    //   item.children[1].src = projects[i].video_mob_webm.publicURL;

    //   item.defaultMuted = 1;

    //   setTimeout(() => {
    //     item.play();
    //   }, i * 500);
    // });

    setTimeout(() => {
      videoEls.current[0].play();
    }, 500);
  }, []);

  useEffect(() => {
    if (sliderEl.current) {
      const all = 8;
      const gCS = typeof window !== 'undefined' && window.getComputedStyle(sliderEl.current);
      const width = parseInt(gCS.width);
      myR.current = width / (2 * Math.tan(Math.PI / all));
      step.current = 360 / all;

      for (let i = 0; i < all; i++) {
        const rad = (i * step.current * Math.PI) / 180;
        if (sliderWrapEl.current.children[i]) {
          sliderWrapEl.current.children[i].style.transform = `
          translate3d(${myR.current * Math.sin(rad)}px,
          0,${myR.current * Math.cos(rad)}px)
          rotateY(${i * step.current}deg)`;
        }
      }
    }
  }, [sliderEl.current]);

  const nav = (d) => {
    if (d !== 0) {
      setVisibleProject(visibleProject - d);
      // videoEls.current[visibleProject - d].play()
    }

    angle.current += step.current * d;
    if (sliderWrapEl.current) {
      sliderWrapEl.current.style.transform = `
      translateZ(${-myR.current}px)
      rotateY(${angle.current}deg)`;
    }
  };

  const handleTouchStart = (e) => {
    setInitialX(e.touches[0].clientX);
    setInitialY(e.touches[0].clientY);

    e.preventDefault();
  };

  const handleTouchMove = (e) => {
    if (initialX === null) {
      return;
    }

    if (initialY === null) {
      return;
    }

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;

    const diffX = initialX - currentX;
    const diffY = initialY - currentY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      // sliding horizontally
      if (diffX > 0) {
        // swiped left/next
        if (visibleProject < projects.length - 1) {
          nav(-1);
        }
      } else {
        // swiped right/prev
        if (visibleProject > 0) {
          nav(1);
        }
      }
    } else {
      // sliding vertically
      if (diffY > 0) {
        // swiped up
      } else {
        // swiped down
      }
    }

    // preventTouch(e);

    setInitialX(null);
    setInitialY(null);

    e.preventDefault();
  };

  useEffect(() => {
    nav(0);
    setVisibleProject(0);
    setTimeout(() => {
      videoEls.current[0].play();
    }, 500);
  }, []);

  useEffect(() => {
    if (!!videoEls.current[visibleProject]) {
      videoEls.current[visibleProject].play();
    }
  }, [visibleProject]);

  return (
    <Wrapper onTouchMove={handleTouchMove} onTouchStart={handleTouchStart} ref={wrapperEl}>
      <Slider ref={sliderEl} hide={visibleProject === null}>
        <SliderWrapper ref={sliderWrapEl}>
          {projects.map((item, i) => {
            return (
              <Slide key={i} hide={i > lastVisibleProject + 1 || i < lastVisibleProject - 1}>
                <VideoWrapper>
                  <Video
                    ref={(el) => (videoEls.current[i] = el)}
                    loop
                    muted={1}
                    defaultMuted
                    preload='auto'
                    playsInline
                    poster={ item.poster_mob.publicURL}
                    autoPlay
                  >
                  <source src={showPoster && item.video_mob_webm.publicURL} type='video/webm' />
                  <source src={showPoster && item.video_mob_mp4.publicURL} type='video/mp4' />
                  {
                    // <source src={''} type='video/mp4' />
                    // <source src={''} type='video/webm' />
                    // <source
                    //   src={showPoster && `https://nonsite.net/8080/${item.video_mob_webm.name}.webm`}
                    //   type='video/webm'
                    // />
                    // <source
                    //   src={showPoster && `https://nonsite.net/8080/${item.video_mob_mp4.name}.mp4`}
                    //   type='video/mp4'
                    // />
                    }
                  </Video>
                </VideoWrapper>
              </Slide>
            );
          })}
        </SliderWrapper>
      </Slider>

      <DescriptionWrapper>
        {projects.map((item, i) => {
          return (
            <Description key={i} hide={i !== visibleProject}>
              <Title href={item.url} target='_blank'>
                {item.title}
              </Title>
              {item.category}
              <div>
                <span
                  dangerouslySetInnerHTML={{
                    __html: remark()
                      .use(remarkHTML)
                      .processSync(item.description)
                      .toString(),
                  }}
                ></span>
              </div>
            </Description>
          );
        })}
      </DescriptionWrapper>
    </Wrapper>
  );
};

export default ObracakMobile;
