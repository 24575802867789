export function toggleAlive(x,y) {
  return {
    type: 'TOGGLE_ALIVE',
    x,
    y
  };
}

export function tick() {
  return {
    type: 'TICK'
  };
}
