import React, {useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { TransitionState } from 'gatsby-plugin-transition-link';

import Layout from '../components/Layout';
import GameOfLife from '../components/GameOfLife';
import ThreeDText from '../components/ThreeDText';
import ObracakMobile from '../components/ObracakMobile';
import Obracak from '../components/Obracak';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;

  @media (min-width: 950px) {
    height: 900vh;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    height: calc(150vh - 200px);
    background-color: var(--secondary);
    box-shadow: 0px 0px 91px 122px var(--secondary);
    opacity: ${({ showBg }) => (showBg ? '1' : '0')};
  }
`;

const FadeWrapper = styled.div`
  opacity: ${({ mount }) => (mount ? 1 : 0)};
  transition: opacity 0.45s ease-in-out;
  /* position: ${({ sticky }) => (sticky ? 'fixed' : 'relative')}; */
`;

const TagLine = styled.h3`
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 18px;
  top: 30px;

  @media (min-width: 700px) {
    top: 35px;
  }
`;

const Section = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 700px) {
    display: block;
    height: 100vh;
    position: ${({ sticky }) => sticky ? 'fixed' : 'absolute'};
    top: ${({ sticky }) => sticky ? '0' : '100vh'};

    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  height: 30px;
  width: 100%;
  text-align: center;
  padding: 35px 0 0;
  color: #a7a7a7;
`;

export const IndexPageContent = ({ data }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 951px)' });
  const [stickySection, setStickySection] = useState(false)

  const checkSticky = () => {
    if (typeof window !== 'undefined' && window.scrollY >= window.innerHeight && !isMobile) {
      setStickySection(true)
    } else {
      setStickySection(false)
    }
  }

  useEffect(() => {
    checkSticky()
    window.addEventListener('scroll', checkSticky)
    
    return () => {
      window.removeEventListener('scroll', checkSticky)
    }
  }, [])
  

  return (
    <TransitionState>
      {({ mount, transitionStatus }) => {
        return (
          <Layout>
            <Wrapper showBg={transitionStatus !== 'entering'}>
              <TagLine>{data.tagline}</TagLine>
              {
                // <ThreeDText isMobile={isMobile} />
                <GameOfLife isMobile={isMobile} />
              }
              <Section sticky={stickySection}>
                {!isMobile && <SectionTitle>8080</SectionTitle>}
                <FadeWrapper mount={mount} sticky={stickySection}>
                  <div>{isMobile && <ObracakMobile projects={data.obracak} />}</div>
                  <div>{!isMobile && <Obracak projects={data.obracak} />}</div>
                  <div>{isMobile && <Footer isMobile={isMobile} mail={data.mail} absolute />}</div>
                </FadeWrapper>
              </Section>
              <div>{!isMobile && <Footer isMobile={isMobile} mail={data.mail} absolute />}</div>
            </Wrapper>
          </Layout>
        );
      }}
    </TransitionState>
  );
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <IndexPageContent data={frontmatter} />;
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        tagline
        obracak {
          title
          url
          category
          description
          poster_dt {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video_mob_mp4 {
            publicURL
            name
            extension
          }
          video_mob_webm {
            publicURL
            name
            extension
          }
          poster_mob {
            publicURL
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video_dt_mp4 {
            publicURL
            name
            extension
          }
          video_dt_webm {
            publicURL
            name
            extension
          }
        }
        mail
      }
    }
  }
`;
