import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';
import { detect } from 'detect-browser';

const Wrapper = styled.div`
  min-height: 100vh;
  background: transparent;

  model-viewer {
    height: 100vh;
    width: 100%;
    z-index: 0;
    background: transparent;

    --progress-bar-color: transparent;
  }
`;

const ThreeDText = ({ isMobile }) => {
  const modelRef = useRef(null);
  const [opa, setOpa] = useState(1);
  const [isSafari14, setIsSafari14] = useState(true);

  useEffect(() => {
    const handleScroll = (e) => {
      const range = window.innerHeight / 3;
      const scrollTop = window.pageYOffset;
      const height = window.innerHeight;
      const offset = height / 2;
      const calc = 1 - (scrollTop - offset + range) / range;

      if (calc > '1') {
        setOpa(1);
      } else if (calc < '0') {
        setOpa(0);
      } else {
        setOpa(calc);
      }
    };

    if (modelRef.current) {
      modelRef.current.addEventListener('load', (ev) => {
        let material = modelRef.current.model.materials[0];

        let createAndApplyTexture = async (channel) => {
          material.pbrMetallicRoughness.setRoughnessFactor(0);
          material.pbrMetallicRoughness.setMetallicFactor(1);
          // material.pbrMetallicRoughness.setBaseColorFactor( [1, 1, 1, 1]);
          material.pbrMetallicRoughness.setBaseColorFactor([0, 1, 0.2, 1]);
        };

        createAndApplyTexture('baseColorTexture');
      });
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [modelRef.current]);

  useEffect(() => {
    const browser = detect();

    if (browser.name.toLowerCase() === 'safari') {
      setIsSafari14(true);
    } else {
      setIsSafari14(false);
    }
  }, []);

  return (
    <Wrapper style={{ opacity: opa }}>
      {
        // <model-viewer
        //   alt='8080'
        //   src={`${withPrefix('/')}img/8080-3d-8.glb`}
        //   ar
        //   ar-modes='webxr scene-viewer quick-look'
        //   seamless-poster
        //   shadow-intensity='0'
        //   camera-controls
        //   disable-zoom
        //   auto-rotate
        //   auto-rotate-delay='0'
        //   interaction-prompt='none'
        //   max-field-of-view={isSafari14 ? '75deg' : '35deg'}
        //   min-field-of-view={isSafari14 ? '75deg' : '35deg'}
        //   rotation-per-second={isSafari14 ? '0deg' : '45deg'}
        //   ref={modelRef}
        //   camera-orbit='-70deg
        //                 calc(90deg + env(window-scroll-y) * 300deg)
        //                 2m'
        // ></model-viewer>
      }
    </Wrapper>
  );
};

export default ThreeDText;
