export default (toggleAlive, isMobile) => {

  const CELL_SIZE = 20;
  const CELL_WIDTH = isMobile ? 22 : 50;
  const CELL_HEIGHT = isMobile ? 20 : 50;

  // const CELL_WIDTH = CELL_SIZE;
  // const CELL_HEIGHT = CELL_SIZE;

  const buildEjt = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 2);
    toggleAlive(startRow, startCol + 3);
    toggleAlive(startRow + 1, startCol + 1);
    toggleAlive(startRow + 1, startCol + 4);
    toggleAlive(startRow + 2, startCol);
    toggleAlive(startRow + 2, startCol + 5);
    toggleAlive(startRow + 3, startCol);
    toggleAlive(startRow + 3, startCol + 5);
    toggleAlive(startRow + 4, startCol + 1);
    toggleAlive(startRow + 4, startCol + 4);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 5, startCol + 3);
    toggleAlive(startRow + 6, startCol + 1);
    toggleAlive(startRow + 6, startCol + 4);
    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 5);
    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 5);
    toggleAlive(startRow + 9, startCol + 1);
    toggleAlive(startRow + 9, startCol + 4);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 10, startCol + 3);
  };

  const buildZero = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 2);
    toggleAlive(startRow, startCol + 3);
    toggleAlive(startRow + 1, startCol + 1);
    toggleAlive(startRow + 1, startCol + 4);
    toggleAlive(startRow + 2, startCol);
    toggleAlive(startRow + 2, startCol + 5);
    toggleAlive(startRow + 3, startCol);
    toggleAlive(startRow + 3, startCol + 5);
    toggleAlive(startRow + 4, startCol);
    toggleAlive(startRow + 4, startCol + 5);
    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 5);
    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 5);
    toggleAlive(startRow + 7, startCol + 5);
    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 5);
    toggleAlive(startRow + 9, startCol + 1);
    toggleAlive(startRow + 9, startCol + 4);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 10, startCol + 3);
  };

  const buildEjtBig = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 5);
    toggleAlive(startRow, startCol + 6);
    toggleAlive(startRow, startCol + 7);
    toggleAlive(startRow, startCol + 8);
    toggleAlive(startRow, startCol + 9);
    toggleAlive(startRow, startCol + 10);

    toggleAlive(startRow + 1, startCol + 3);
    toggleAlive(startRow + 1, startCol + 4);
    toggleAlive(startRow + 1, startCol + 5);
    toggleAlive(startRow + 1, startCol + 6);
    toggleAlive(startRow + 1, startCol + 7);
    toggleAlive(startRow + 1, startCol + 8);
    toggleAlive(startRow + 1, startCol + 9);
    toggleAlive(startRow + 1, startCol + 10);
    toggleAlive(startRow + 1, startCol + 11);
    toggleAlive(startRow + 1, startCol + 12);

    toggleAlive(startRow + 2, startCol + 2);
    toggleAlive(startRow + 2, startCol + 3);
    toggleAlive(startRow + 2, startCol + 4);
    toggleAlive(startRow + 2, startCol + 5);
    toggleAlive(startRow + 2, startCol + 10);
    toggleAlive(startRow + 2, startCol + 11);
    toggleAlive(startRow + 2, startCol + 12);
    toggleAlive(startRow + 2, startCol + 13);

    toggleAlive(startRow + 3, startCol + 1);
    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 3, startCol + 3);
    toggleAlive(startRow + 3, startCol + 12);
    toggleAlive(startRow + 3, startCol + 13);
    toggleAlive(startRow + 3, startCol + 14);

    toggleAlive(startRow + 4, startCol + 1);
    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 4, startCol + 13);
    toggleAlive(startRow + 4, startCol + 14);

    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 1);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 5, startCol + 13);
    toggleAlive(startRow + 5, startCol + 14);
    toggleAlive(startRow + 5, startCol + 15);

    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 1);
    toggleAlive(startRow + 6, startCol + 2);
    toggleAlive(startRow + 6, startCol + 13);
    toggleAlive(startRow + 6, startCol + 14);
    toggleAlive(startRow + 6, startCol + 15);

    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 1);
    toggleAlive(startRow + 7, startCol + 14);
    toggleAlive(startRow + 7, startCol + 15);

    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 1);
    toggleAlive(startRow + 8, startCol + 14);
    toggleAlive(startRow + 8, startCol + 15);

    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 1);
    toggleAlive(startRow + 9, startCol + 14);
    toggleAlive(startRow + 9, startCol + 15);

    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 1);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 10, startCol + 13);
    toggleAlive(startRow + 10, startCol + 14);
    toggleAlive(startRow + 10, startCol + 15);

    toggleAlive(startRow + 11, startCol + 1);
    toggleAlive(startRow + 11, startCol + 2);
    toggleAlive(startRow + 11, startCol + 13);
    toggleAlive(startRow + 11, startCol + 14);

    toggleAlive(startRow + 12, startCol + 1);
    toggleAlive(startRow + 12, startCol + 2);
    toggleAlive(startRow + 12, startCol + 13);
    toggleAlive(startRow + 12, startCol + 14);

    toggleAlive(startRow + 13, startCol + 1);
    toggleAlive(startRow + 13, startCol + 2);
    toggleAlive(startRow + 13, startCol + 3);
    toggleAlive(startRow + 13, startCol + 12);
    toggleAlive(startRow + 13, startCol + 13);
    toggleAlive(startRow + 13, startCol + 14);

    toggleAlive(startRow + 14, startCol + 2);
    toggleAlive(startRow + 14, startCol + 3);
    toggleAlive(startRow + 14, startCol + 4);
    toggleAlive(startRow + 14, startCol + 5);
    toggleAlive(startRow + 14, startCol + 10);
    toggleAlive(startRow + 14, startCol + 11);
    toggleAlive(startRow + 14, startCol + 12);
    toggleAlive(startRow + 14, startCol + 13);

    toggleAlive(startRow + 15, startCol + 4);
    toggleAlive(startRow + 15, startCol + 5);
    toggleAlive(startRow + 15, startCol + 6);
    toggleAlive(startRow + 15, startCol + 7);
    toggleAlive(startRow + 15, startCol + 8);
    toggleAlive(startRow + 15, startCol + 9);
    toggleAlive(startRow + 15, startCol + 10);
    toggleAlive(startRow + 15, startCol + 11);

    toggleAlive(startRow + 16, startCol + 4);
    toggleAlive(startRow + 16, startCol + 5);
    toggleAlive(startRow + 16, startCol + 6);
    toggleAlive(startRow + 16, startCol + 7);
    toggleAlive(startRow + 16, startCol + 8);
    toggleAlive(startRow + 16, startCol + 9);
    toggleAlive(startRow + 16, startCol + 10);
    toggleAlive(startRow + 16, startCol + 11);

    toggleAlive(startRow + 17, startCol + 2);
    toggleAlive(startRow + 17, startCol + 3);
    toggleAlive(startRow + 17, startCol + 4);
    toggleAlive(startRow + 17, startCol + 5);
    toggleAlive(startRow + 17, startCol + 10);
    toggleAlive(startRow + 17, startCol + 11);
    toggleAlive(startRow + 17, startCol + 12);
    toggleAlive(startRow + 17, startCol + 13);

    toggleAlive(startRow + 18, startCol + 1);
    toggleAlive(startRow + 18, startCol + 2);
    toggleAlive(startRow + 18, startCol + 3);
    toggleAlive(startRow + 18, startCol + 12);
    toggleAlive(startRow + 18, startCol + 13);
    toggleAlive(startRow + 18, startCol + 14);

    toggleAlive(startRow + 19, startCol + 1);
    toggleAlive(startRow + 19, startCol + 2);
    toggleAlive(startRow + 19, startCol + 13);
    toggleAlive(startRow + 19, startCol + 14);

    toggleAlive(startRow + 20, startCol + 1);
    toggleAlive(startRow + 20, startCol + 2);
    toggleAlive(startRow + 20, startCol + 13);
    toggleAlive(startRow + 20, startCol + 14);

    toggleAlive(startRow + 21, startCol);
    toggleAlive(startRow + 21, startCol + 1);
    toggleAlive(startRow + 21, startCol + 2);
    toggleAlive(startRow + 21, startCol + 13);
    toggleAlive(startRow + 21, startCol + 14);
    toggleAlive(startRow + 21, startCol + 15);

    toggleAlive(startRow + 22, startCol);
    toggleAlive(startRow + 22, startCol + 1);
    toggleAlive(startRow + 22, startCol + 14);
    toggleAlive(startRow + 22, startCol + 15);

    toggleAlive(startRow + 23, startCol);
    toggleAlive(startRow + 23, startCol + 1);
    toggleAlive(startRow + 23, startCol + 14);
    toggleAlive(startRow + 23, startCol + 15);

    toggleAlive(startRow + 24, startCol);
    toggleAlive(startRow + 24, startCol + 1);
    toggleAlive(startRow + 24, startCol + 14);
    toggleAlive(startRow + 24, startCol + 15);

    toggleAlive(startRow + 25, startCol);
    toggleAlive(startRow + 25, startCol + 1);
    toggleAlive(startRow + 25, startCol + 2);
    toggleAlive(startRow + 25, startCol + 13);
    toggleAlive(startRow + 25, startCol + 14);
    toggleAlive(startRow + 25, startCol + 15);

    toggleAlive(startRow + 26, startCol);
    toggleAlive(startRow + 26, startCol + 1);
    toggleAlive(startRow + 26, startCol + 2);
    toggleAlive(startRow + 26, startCol + 13);
    toggleAlive(startRow + 26, startCol + 14);
    toggleAlive(startRow + 26, startCol + 15);

    toggleAlive(startRow + 27, startCol + 1);
    toggleAlive(startRow + 27, startCol + 2);
    toggleAlive(startRow + 27, startCol + 13);
    toggleAlive(startRow + 27, startCol + 14);

    toggleAlive(startRow + 28, startCol + 1);
    toggleAlive(startRow + 28, startCol + 2);
    toggleAlive(startRow + 28, startCol + 3);
    toggleAlive(startRow + 28, startCol + 12);
    toggleAlive(startRow + 28, startCol + 13);
    toggleAlive(startRow + 28, startCol + 14);

    toggleAlive(startRow + 29, startCol + 2);
    toggleAlive(startRow + 29, startCol + 3);
    toggleAlive(startRow + 29, startCol + 4);
    toggleAlive(startRow + 29, startCol + 5);
    toggleAlive(startRow + 29, startCol + 10);
    toggleAlive(startRow + 29, startCol + 11);
    toggleAlive(startRow + 29, startCol + 12);
    toggleAlive(startRow + 29, startCol + 13);

    toggleAlive(startRow + 30, startCol + 3);
    toggleAlive(startRow + 30, startCol + 4);
    toggleAlive(startRow + 30, startCol + 5);
    toggleAlive(startRow + 30, startCol + 6);
    toggleAlive(startRow + 30, startCol + 7);
    toggleAlive(startRow + 30, startCol + 8);
    toggleAlive(startRow + 30, startCol + 9);
    toggleAlive(startRow + 30, startCol + 10);
    toggleAlive(startRow + 30, startCol + 11);
    toggleAlive(startRow + 30, startCol + 12);

    toggleAlive(startRow + 31, startCol + 5);
    toggleAlive(startRow + 31, startCol + 6);
    toggleAlive(startRow + 31, startCol + 7);
    toggleAlive(startRow + 31, startCol + 8);
    toggleAlive(startRow + 31, startCol + 9);
    toggleAlive(startRow + 31, startCol + 10);
  };

  const buildZeroBig = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 5);
    toggleAlive(startRow, startCol + 6);
    toggleAlive(startRow, startCol + 7);
    toggleAlive(startRow, startCol + 8);
    toggleAlive(startRow, startCol + 9);
    toggleAlive(startRow, startCol + 10);

    toggleAlive(startRow + 1, startCol + 3);
    toggleAlive(startRow + 1, startCol + 4);
    toggleAlive(startRow + 1, startCol + 5);
    toggleAlive(startRow + 1, startCol + 6);
    toggleAlive(startRow + 1, startCol + 7);
    toggleAlive(startRow + 1, startCol + 8);
    toggleAlive(startRow + 1, startCol + 9);
    toggleAlive(startRow + 1, startCol + 10);
    toggleAlive(startRow + 1, startCol + 11);
    toggleAlive(startRow + 1, startCol + 12);

    toggleAlive(startRow + 2, startCol + 2);
    toggleAlive(startRow + 2, startCol + 3);
    toggleAlive(startRow + 2, startCol + 4);
    toggleAlive(startRow + 2, startCol + 5);
    toggleAlive(startRow + 2, startCol + 10);
    toggleAlive(startRow + 2, startCol + 11);
    toggleAlive(startRow + 2, startCol + 12);
    toggleAlive(startRow + 2, startCol + 13);

    toggleAlive(startRow + 3, startCol + 1);
    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 3, startCol + 3);
    toggleAlive(startRow + 3, startCol + 12);
    toggleAlive(startRow + 3, startCol + 13);
    toggleAlive(startRow + 3, startCol + 14);

    toggleAlive(startRow + 4, startCol + 1);
    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 4, startCol + 13);
    toggleAlive(startRow + 4, startCol + 14);

    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 1);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 5, startCol + 13);
    toggleAlive(startRow + 5, startCol + 14);
    toggleAlive(startRow + 5, startCol + 15);

    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 1);
    toggleAlive(startRow + 6, startCol + 2);
    toggleAlive(startRow + 6, startCol + 13);
    toggleAlive(startRow + 6, startCol + 14);
    toggleAlive(startRow + 6, startCol + 15);

    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 1);
    toggleAlive(startRow + 7, startCol + 14);
    toggleAlive(startRow + 7, startCol + 15);

    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 1);
    toggleAlive(startRow + 8, startCol + 14);
    toggleAlive(startRow + 8, startCol + 15);

    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 1);
    toggleAlive(startRow + 9, startCol + 14);
    toggleAlive(startRow + 9, startCol + 15);

    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 1);
    toggleAlive(startRow + 10, startCol + 14);
    toggleAlive(startRow + 10, startCol + 15);

    toggleAlive(startRow + 11, startCol);
    toggleAlive(startRow + 11, startCol + 1);
    toggleAlive(startRow + 11, startCol + 14);
    toggleAlive(startRow + 11, startCol + 15);

    toggleAlive(startRow + 12, startCol);
    toggleAlive(startRow + 12, startCol + 1);
    toggleAlive(startRow + 12, startCol + 14);
    toggleAlive(startRow + 12, startCol + 15);

    toggleAlive(startRow + 13, startCol);
    toggleAlive(startRow + 13, startCol + 1);
    toggleAlive(startRow + 13, startCol + 14);
    toggleAlive(startRow + 13, startCol + 15);

    toggleAlive(startRow + 14, startCol);
    toggleAlive(startRow + 14, startCol + 1);
    toggleAlive(startRow + 14, startCol + 14);
    toggleAlive(startRow + 14, startCol + 15);

    toggleAlive(startRow + 15, startCol);
    toggleAlive(startRow + 15, startCol + 1);
    toggleAlive(startRow + 15, startCol + 14);
    toggleAlive(startRow + 15, startCol + 15);

    toggleAlive(startRow + 16, startCol);
    toggleAlive(startRow + 16, startCol + 1);
    toggleAlive(startRow + 16, startCol + 14);
    toggleAlive(startRow + 16, startCol + 15);

    toggleAlive(startRow + 17, startCol);
    toggleAlive(startRow + 17, startCol + 1);
    toggleAlive(startRow + 17, startCol + 14);
    toggleAlive(startRow + 17, startCol + 15);

    toggleAlive(startRow + 18, startCol);
    toggleAlive(startRow + 18, startCol + 1);
    toggleAlive(startRow + 18, startCol + 14);
    toggleAlive(startRow + 18, startCol + 15);

    toggleAlive(startRow + 19, startCol);
    toggleAlive(startRow + 19, startCol + 1);
    toggleAlive(startRow + 19, startCol + 14);
    toggleAlive(startRow + 19, startCol + 15);

    toggleAlive(startRow + 20, startCol);
    toggleAlive(startRow + 20, startCol + 1);
    toggleAlive(startRow + 20, startCol + 14);
    toggleAlive(startRow + 20, startCol + 15);

    toggleAlive(startRow + 21, startCol);
    toggleAlive(startRow + 21, startCol + 1);
    toggleAlive(startRow + 21, startCol + 14);
    toggleAlive(startRow + 21, startCol + 15);

    toggleAlive(startRow + 22, startCol);
    toggleAlive(startRow + 22, startCol + 1);
    toggleAlive(startRow + 22, startCol + 14);
    toggleAlive(startRow + 22, startCol + 15);

    toggleAlive(startRow + 23, startCol);
    toggleAlive(startRow + 23, startCol + 1);
    toggleAlive(startRow + 23, startCol + 14);
    toggleAlive(startRow + 23, startCol + 15);

    toggleAlive(startRow + 24, startCol);
    toggleAlive(startRow + 24, startCol + 1);
    toggleAlive(startRow + 24, startCol + 14);
    toggleAlive(startRow + 24, startCol + 15);

    toggleAlive(startRow + 25, startCol);
    toggleAlive(startRow + 25, startCol + 1);
    toggleAlive(startRow + 25, startCol + 2);
    toggleAlive(startRow + 25, startCol + 13);
    toggleAlive(startRow + 25, startCol + 14);
    toggleAlive(startRow + 25, startCol + 15);

    toggleAlive(startRow + 26, startCol);
    toggleAlive(startRow + 26, startCol + 1);
    toggleAlive(startRow + 26, startCol + 2);
    toggleAlive(startRow + 26, startCol + 13);
    toggleAlive(startRow + 26, startCol + 14);
    toggleAlive(startRow + 26, startCol + 15);

    toggleAlive(startRow + 27, startCol + 1);
    toggleAlive(startRow + 27, startCol + 2);
    toggleAlive(startRow + 27, startCol + 13);
    toggleAlive(startRow + 27, startCol + 14);

    toggleAlive(startRow + 28, startCol + 1);
    toggleAlive(startRow + 28, startCol + 2);
    toggleAlive(startRow + 28, startCol + 3);
    toggleAlive(startRow + 28, startCol + 12);
    toggleAlive(startRow + 28, startCol + 13);
    toggleAlive(startRow + 28, startCol + 14);

    toggleAlive(startRow + 29, startCol + 2);
    toggleAlive(startRow + 29, startCol + 3);
    toggleAlive(startRow + 29, startCol + 4);
    toggleAlive(startRow + 29, startCol + 5);
    toggleAlive(startRow + 29, startCol + 10);
    toggleAlive(startRow + 29, startCol + 11);
    toggleAlive(startRow + 29, startCol + 12);
    toggleAlive(startRow + 29, startCol + 13);

    toggleAlive(startRow + 30, startCol + 3);
    toggleAlive(startRow + 30, startCol + 4);
    toggleAlive(startRow + 30, startCol + 5);
    toggleAlive(startRow + 30, startCol + 6);
    toggleAlive(startRow + 30, startCol + 7);
    toggleAlive(startRow + 30, startCol + 8);
    toggleAlive(startRow + 30, startCol + 9);
    toggleAlive(startRow + 30, startCol + 10);
    toggleAlive(startRow + 30, startCol + 11);
    toggleAlive(startRow + 30, startCol + 12);

    toggleAlive(startRow + 31, startCol + 5);
    toggleAlive(startRow + 31, startCol + 6);
    toggleAlive(startRow + 31, startCol + 7);
    toggleAlive(startRow + 31, startCol + 8);
    toggleAlive(startRow + 31, startCol + 9);
    toggleAlive(startRow + 31, startCol + 10);
  };

  const buildEjtBigSkewed = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 7);
    toggleAlive(startRow, startCol + 8);
    toggleAlive(startRow, startCol + 9);
    toggleAlive(startRow, startCol + 10);
    toggleAlive(startRow, startCol + 11);
    toggleAlive(startRow, startCol + 12);

    toggleAlive(startRow + 1, startCol + 4);
    toggleAlive(startRow + 1, startCol + 5);
    toggleAlive(startRow + 1, startCol + 6);
    toggleAlive(startRow + 1, startCol + 7);
    toggleAlive(startRow + 1, startCol + 8);
    toggleAlive(startRow + 1, startCol + 9);
    toggleAlive(startRow + 1, startCol + 10);
    toggleAlive(startRow + 1, startCol + 11);
    toggleAlive(startRow + 1, startCol + 12);
    toggleAlive(startRow + 1, startCol + 13);

    toggleAlive(startRow + 2, startCol + 3);
    toggleAlive(startRow + 2, startCol + 4);
    toggleAlive(startRow + 2, startCol + 5);
    toggleAlive(startRow + 2, startCol + 6);
    toggleAlive(startRow + 2, startCol + 11);
    toggleAlive(startRow + 2, startCol + 12);
    toggleAlive(startRow + 2, startCol + 13);
    toggleAlive(startRow + 2, startCol + 14);

    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 3, startCol + 3);
    toggleAlive(startRow + 3, startCol + 4);
    toggleAlive(startRow + 3, startCol + 13);
    toggleAlive(startRow + 3, startCol + 14);
    toggleAlive(startRow + 3, startCol + 15);

    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 4, startCol + 3);
    toggleAlive(startRow + 4, startCol + 14);
    toggleAlive(startRow + 4, startCol + 15);

    toggleAlive(startRow + 5, startCol + 1);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 5, startCol + 3);
    toggleAlive(startRow + 5, startCol + 14);
    toggleAlive(startRow + 5, startCol + 15);
    toggleAlive(startRow + 5, startCol + 16);

    toggleAlive(startRow + 6, startCol + 1);
    toggleAlive(startRow + 6, startCol + 2);
    toggleAlive(startRow + 6, startCol + 3);
    toggleAlive(startRow + 6, startCol + 14);
    toggleAlive(startRow + 6, startCol + 15);
    toggleAlive(startRow + 6, startCol + 16);

    toggleAlive(startRow + 7, startCol + 1);
    toggleAlive(startRow + 7, startCol + 2);
    toggleAlive(startRow + 7, startCol + 15);
    toggleAlive(startRow + 7, startCol + 16);

    toggleAlive(startRow + 8, startCol + 1);
    toggleAlive(startRow + 8, startCol + 2);
    toggleAlive(startRow + 8, startCol + 15);
    toggleAlive(startRow + 8, startCol + 16);

    toggleAlive(startRow + 9, startCol + 1);
    toggleAlive(startRow + 9, startCol + 2);
    toggleAlive(startRow + 9, startCol + 15);
    toggleAlive(startRow + 9, startCol + 16);

    toggleAlive(startRow + 10, startCol + 1);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 10, startCol + 3);
    toggleAlive(startRow + 10, startCol + 14);
    toggleAlive(startRow + 10, startCol + 15);
    toggleAlive(startRow + 10, startCol + 16);

    toggleAlive(startRow + 11, startCol + 2);
    toggleAlive(startRow + 11, startCol + 3);
    toggleAlive(startRow + 11, startCol + 14);
    toggleAlive(startRow + 11, startCol + 15);

    toggleAlive(startRow + 12, startCol + 2);
    toggleAlive(startRow + 12, startCol + 3);
    toggleAlive(startRow + 12, startCol + 14);
    toggleAlive(startRow + 12, startCol + 15);

    toggleAlive(startRow + 13, startCol + 2);
    toggleAlive(startRow + 13, startCol + 3);
    toggleAlive(startRow + 13, startCol + 4);
    toggleAlive(startRow + 13, startCol + 13);
    toggleAlive(startRow + 13, startCol + 14);
    toggleAlive(startRow + 13, startCol + 15);

    toggleAlive(startRow + 14, startCol + 3);
    toggleAlive(startRow + 14, startCol + 4);
    toggleAlive(startRow + 14, startCol + 5);
    toggleAlive(startRow + 14, startCol + 6);
    toggleAlive(startRow + 14, startCol + 11);
    toggleAlive(startRow + 14, startCol + 12);
    toggleAlive(startRow + 14, startCol + 13);
    toggleAlive(startRow + 14, startCol + 14);

    toggleAlive(startRow + 15, startCol + 5);
    toggleAlive(startRow + 15, startCol + 6);
    toggleAlive(startRow + 15, startCol + 7);
    toggleAlive(startRow + 15, startCol + 8);
    toggleAlive(startRow + 15, startCol + 9);
    toggleAlive(startRow + 15, startCol + 10);
    toggleAlive(startRow + 15, startCol + 11);
    toggleAlive(startRow + 15, startCol + 12);

    toggleAlive(startRow + 16, startCol + 4);
    toggleAlive(startRow + 16, startCol + 5);
    toggleAlive(startRow + 16, startCol + 6);
    toggleAlive(startRow + 16, startCol + 7);
    toggleAlive(startRow + 16, startCol + 8);
    toggleAlive(startRow + 16, startCol + 9);
    toggleAlive(startRow + 16, startCol + 10);
    toggleAlive(startRow + 16, startCol + 11);

    toggleAlive(startRow + 17, startCol + 2);
    toggleAlive(startRow + 17, startCol + 3);
    toggleAlive(startRow + 17, startCol + 4);
    toggleAlive(startRow + 17, startCol + 5);
    toggleAlive(startRow + 17, startCol + 10);
    toggleAlive(startRow + 17, startCol + 11);
    toggleAlive(startRow + 17, startCol + 12);
    toggleAlive(startRow + 17, startCol + 13);

    toggleAlive(startRow + 18, startCol + 1);
    toggleAlive(startRow + 18, startCol + 2);
    toggleAlive(startRow + 18, startCol + 3);
    toggleAlive(startRow + 18, startCol + 12);
    toggleAlive(startRow + 18, startCol + 13);
    toggleAlive(startRow + 18, startCol + 14);

    toggleAlive(startRow + 19, startCol + 1);
    toggleAlive(startRow + 19, startCol + 2);
    toggleAlive(startRow + 19, startCol + 13);
    toggleAlive(startRow + 19, startCol + 14);

    toggleAlive(startRow + 20, startCol + 1);
    toggleAlive(startRow + 20, startCol + 2);
    toggleAlive(startRow + 20, startCol + 13);
    toggleAlive(startRow + 20, startCol + 14);

    toggleAlive(startRow + 21, startCol);
    toggleAlive(startRow + 21, startCol + 1);
    toggleAlive(startRow + 21, startCol + 2);
    toggleAlive(startRow + 21, startCol + 13);
    toggleAlive(startRow + 21, startCol + 14);
    toggleAlive(startRow + 21, startCol + 15);

    toggleAlive(startRow + 22, startCol);
    toggleAlive(startRow + 22, startCol + 1);
    toggleAlive(startRow + 22, startCol + 14);
    toggleAlive(startRow + 22, startCol + 15);

    toggleAlive(startRow + 23, startCol);
    toggleAlive(startRow + 23, startCol + 1);
    toggleAlive(startRow + 23, startCol + 14);
    toggleAlive(startRow + 23, startCol + 15);

    toggleAlive(startRow + 24, startCol);
    toggleAlive(startRow + 24, startCol + 1);
    toggleAlive(startRow + 24, startCol + 14);
    toggleAlive(startRow + 24, startCol + 15);

    toggleAlive(startRow + 25, startCol);
    toggleAlive(startRow + 25, startCol + 1);
    toggleAlive(startRow + 25, startCol + 2);
    toggleAlive(startRow + 25, startCol + 13);
    toggleAlive(startRow + 25, startCol + 14);
    toggleAlive(startRow + 25, startCol + 15);

    toggleAlive(startRow + 26, startCol);
    toggleAlive(startRow + 26, startCol + 1);
    toggleAlive(startRow + 26, startCol + 2);
    toggleAlive(startRow + 26, startCol + 13);
    toggleAlive(startRow + 26, startCol + 14);
    toggleAlive(startRow + 26, startCol + 15);

    toggleAlive(startRow + 27, startCol + 1);
    toggleAlive(startRow + 27, startCol + 2);
    toggleAlive(startRow + 27, startCol + 13);
    toggleAlive(startRow + 27, startCol + 14);

    toggleAlive(startRow + 28, startCol + 1);
    toggleAlive(startRow + 28, startCol + 2);
    toggleAlive(startRow + 28, startCol + 3);
    toggleAlive(startRow + 28, startCol + 12);
    toggleAlive(startRow + 28, startCol + 13);
    toggleAlive(startRow + 28, startCol + 14);

    toggleAlive(startRow + 29, startCol + 2);
    toggleAlive(startRow + 29, startCol + 3);
    toggleAlive(startRow + 29, startCol + 4);
    toggleAlive(startRow + 29, startCol + 5);
    toggleAlive(startRow + 29, startCol + 10);
    toggleAlive(startRow + 29, startCol + 11);
    toggleAlive(startRow + 29, startCol + 12);
    toggleAlive(startRow + 29, startCol + 13);

    toggleAlive(startRow + 30, startCol + 3);
    toggleAlive(startRow + 30, startCol + 4);
    toggleAlive(startRow + 30, startCol + 5);
    toggleAlive(startRow + 30, startCol + 6);
    toggleAlive(startRow + 30, startCol + 7);
    toggleAlive(startRow + 30, startCol + 8);
    toggleAlive(startRow + 30, startCol + 9);
    toggleAlive(startRow + 30, startCol + 10);
    toggleAlive(startRow + 30, startCol + 11);
    toggleAlive(startRow + 30, startCol + 12);

    toggleAlive(startRow + 31, startCol + 5);
    toggleAlive(startRow + 31, startCol + 6);
    toggleAlive(startRow + 31, startCol + 7);
    toggleAlive(startRow + 31, startCol + 8);
    toggleAlive(startRow + 31, startCol + 9);
    toggleAlive(startRow + 31, startCol + 10);
  };

  const buildZeroBigSkewed = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 7);
    toggleAlive(startRow, startCol + 8);
    toggleAlive(startRow, startCol + 9);
    toggleAlive(startRow, startCol + 10);
    toggleAlive(startRow, startCol + 11);
    toggleAlive(startRow, startCol + 12);

    toggleAlive(startRow + 1, startCol + 4);
    toggleAlive(startRow + 1, startCol + 5);
    toggleAlive(startRow + 1, startCol + 6);
    toggleAlive(startRow + 1, startCol + 7);
    toggleAlive(startRow + 1, startCol + 8);
    toggleAlive(startRow + 1, startCol + 9);
    toggleAlive(startRow + 1, startCol + 10);
    toggleAlive(startRow + 1, startCol + 11);
    toggleAlive(startRow + 1, startCol + 12);
    toggleAlive(startRow + 1, startCol + 13);

    toggleAlive(startRow + 2, startCol + 3);
    toggleAlive(startRow + 2, startCol + 4);
    toggleAlive(startRow + 2, startCol + 5);
    toggleAlive(startRow + 2, startCol + 6);
    toggleAlive(startRow + 2, startCol + 11);
    toggleAlive(startRow + 2, startCol + 12);
    toggleAlive(startRow + 2, startCol + 13);
    toggleAlive(startRow + 2, startCol + 14);

    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 3, startCol + 3);
    toggleAlive(startRow + 3, startCol + 4);
    toggleAlive(startRow + 3, startCol + 13);
    toggleAlive(startRow + 3, startCol + 14);
    toggleAlive(startRow + 3, startCol + 15);

    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 4, startCol + 3);
    toggleAlive(startRow + 4, startCol + 14);
    toggleAlive(startRow + 4, startCol + 15);

    toggleAlive(startRow + 5, startCol + 1);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 5, startCol + 3);
    toggleAlive(startRow + 5, startCol + 14);
    toggleAlive(startRow + 5, startCol + 15);
    toggleAlive(startRow + 5, startCol + 16);

    toggleAlive(startRow + 6, startCol + 1);
    toggleAlive(startRow + 6, startCol + 2);
    toggleAlive(startRow + 6, startCol + 3);
    toggleAlive(startRow + 6, startCol + 14);
    toggleAlive(startRow + 6, startCol + 15);
    toggleAlive(startRow + 6, startCol + 16);

    toggleAlive(startRow + 7, startCol + 1);
    toggleAlive(startRow + 7, startCol + 2);
    toggleAlive(startRow + 7, startCol + 15);
    toggleAlive(startRow + 7, startCol + 16);

    toggleAlive(startRow + 8, startCol + 1);
    toggleAlive(startRow + 8, startCol + 2);
    toggleAlive(startRow + 8, startCol + 15);
    toggleAlive(startRow + 8, startCol + 16);

    toggleAlive(startRow + 9, startCol + 1);
    toggleAlive(startRow + 9, startCol + 2);
    toggleAlive(startRow + 9, startCol + 15);
    toggleAlive(startRow + 9, startCol + 16);

    toggleAlive(startRow + 10, startCol + 1);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 10, startCol + 15);
    toggleAlive(startRow + 10, startCol + 16);

    toggleAlive(startRow + 11, startCol + 1);
    toggleAlive(startRow + 11, startCol + 2);
    toggleAlive(startRow + 11, startCol + 15);
    toggleAlive(startRow + 11, startCol + 16);

    toggleAlive(startRow + 12, startCol + 1);
    toggleAlive(startRow + 12, startCol + 2);
    toggleAlive(startRow + 12, startCol + 15);
    toggleAlive(startRow + 12, startCol + 16);

    toggleAlive(startRow + 13, startCol + 1);
    toggleAlive(startRow + 13, startCol + 2);
    toggleAlive(startRow + 13, startCol + 15);
    toggleAlive(startRow + 13, startCol + 16);

    toggleAlive(startRow + 14, startCol + 1);
    toggleAlive(startRow + 14, startCol + 2);
    toggleAlive(startRow + 14, startCol + 15);
    toggleAlive(startRow + 14, startCol + 16);

    toggleAlive(startRow + 15, startCol + 1);
    toggleAlive(startRow + 15, startCol + 2);
    toggleAlive(startRow + 15, startCol + 15);
    toggleAlive(startRow + 15, startCol + 16);

    toggleAlive(startRow + 16, startCol);
    toggleAlive(startRow + 16, startCol + 1);
    toggleAlive(startRow + 16, startCol + 14);
    toggleAlive(startRow + 16, startCol + 15);

    toggleAlive(startRow + 17, startCol);
    toggleAlive(startRow + 17, startCol + 1);
    toggleAlive(startRow + 17, startCol + 14);
    toggleAlive(startRow + 17, startCol + 15);

    toggleAlive(startRow + 18, startCol);
    toggleAlive(startRow + 18, startCol + 1);
    toggleAlive(startRow + 18, startCol + 14);
    toggleAlive(startRow + 18, startCol + 15);

    toggleAlive(startRow + 19, startCol);
    toggleAlive(startRow + 19, startCol + 1);
    toggleAlive(startRow + 19, startCol + 14);
    toggleAlive(startRow + 19, startCol + 15);

    toggleAlive(startRow + 20, startCol);
    toggleAlive(startRow + 20, startCol + 1);
    toggleAlive(startRow + 20, startCol + 14);
    toggleAlive(startRow + 20, startCol + 15);

    toggleAlive(startRow + 21, startCol);
    toggleAlive(startRow + 21, startCol + 1);
    toggleAlive(startRow + 21, startCol + 14);
    toggleAlive(startRow + 21, startCol + 15);

    toggleAlive(startRow + 22, startCol);
    toggleAlive(startRow + 22, startCol + 1);
    toggleAlive(startRow + 22, startCol + 14);
    toggleAlive(startRow + 22, startCol + 15);

    toggleAlive(startRow + 23, startCol);
    toggleAlive(startRow + 23, startCol + 1);
    toggleAlive(startRow + 23, startCol + 14);
    toggleAlive(startRow + 23, startCol + 15);

    toggleAlive(startRow + 24, startCol);
    toggleAlive(startRow + 24, startCol + 1);
    toggleAlive(startRow + 24, startCol + 14);
    toggleAlive(startRow + 24, startCol + 15);

    toggleAlive(startRow + 25, startCol);
    toggleAlive(startRow + 25, startCol + 1);
    toggleAlive(startRow + 25, startCol + 2);
    toggleAlive(startRow + 25, startCol + 13);
    toggleAlive(startRow + 25, startCol + 14);
    toggleAlive(startRow + 25, startCol + 15);

    toggleAlive(startRow + 26, startCol);
    toggleAlive(startRow + 26, startCol + 1);
    toggleAlive(startRow + 26, startCol + 2);
    toggleAlive(startRow + 26, startCol + 13);
    toggleAlive(startRow + 26, startCol + 14);
    toggleAlive(startRow + 26, startCol + 15);

    toggleAlive(startRow + 27, startCol + 1);
    toggleAlive(startRow + 27, startCol + 2);
    toggleAlive(startRow + 27, startCol + 13);
    toggleAlive(startRow + 27, startCol + 14);

    toggleAlive(startRow + 28, startCol + 1);
    toggleAlive(startRow + 28, startCol + 2);
    toggleAlive(startRow + 28, startCol + 3);
    toggleAlive(startRow + 28, startCol + 12);
    toggleAlive(startRow + 28, startCol + 13);
    toggleAlive(startRow + 28, startCol + 14);

    toggleAlive(startRow + 29, startCol + 2);
    toggleAlive(startRow + 29, startCol + 3);
    toggleAlive(startRow + 29, startCol + 4);
    toggleAlive(startRow + 29, startCol + 5);
    toggleAlive(startRow + 29, startCol + 10);
    toggleAlive(startRow + 29, startCol + 11);
    toggleAlive(startRow + 29, startCol + 12);
    toggleAlive(startRow + 29, startCol + 13);

    toggleAlive(startRow + 30, startCol + 3);
    toggleAlive(startRow + 30, startCol + 4);
    toggleAlive(startRow + 30, startCol + 5);
    toggleAlive(startRow + 30, startCol + 6);
    toggleAlive(startRow + 30, startCol + 7);
    toggleAlive(startRow + 30, startCol + 8);
    toggleAlive(startRow + 30, startCol + 9);
    toggleAlive(startRow + 30, startCol + 10);
    toggleAlive(startRow + 30, startCol + 11);
    toggleAlive(startRow + 30, startCol + 12);

    toggleAlive(startRow + 31, startCol + 5);
    toggleAlive(startRow + 31, startCol + 6);
    toggleAlive(startRow + 31, startCol + 7);
    toggleAlive(startRow + 31, startCol + 8);
    toggleAlive(startRow + 31, startCol + 9);
    toggleAlive(startRow + 31, startCol + 10);
  };

  const buildEjtBigRotate = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 4);
    toggleAlive(startRow, startCol + 5);
    toggleAlive(startRow, startCol + 6);
    toggleAlive(startRow, startCol + 7);
    toggleAlive(startRow, startCol + 8);
    toggleAlive(startRow, startCol + 9);

    toggleAlive(startRow + 1, startCol + 3);
    toggleAlive(startRow + 1, startCol + 4);
    toggleAlive(startRow + 1, startCol + 5);
    toggleAlive(startRow + 1, startCol + 6);
    toggleAlive(startRow + 1, startCol + 7);
    toggleAlive(startRow + 1, startCol + 8);
    toggleAlive(startRow + 1, startCol + 9);
    toggleAlive(startRow + 1, startCol + 10);
    toggleAlive(startRow + 1, startCol + 11);
    toggleAlive(startRow + 1, startCol + 12);

    toggleAlive(startRow + 2, startCol + 2);
    toggleAlive(startRow + 2, startCol + 3);
    toggleAlive(startRow + 2, startCol + 4);
    toggleAlive(startRow + 2, startCol + 5);
    toggleAlive(startRow + 2, startCol + 10);
    toggleAlive(startRow + 2, startCol + 11);
    toggleAlive(startRow + 2, startCol + 12);
    toggleAlive(startRow + 2, startCol + 13);

    toggleAlive(startRow + 3, startCol + 1);
    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 3, startCol + 3);
    toggleAlive(startRow + 3, startCol + 12);
    toggleAlive(startRow + 3, startCol + 13);
    toggleAlive(startRow + 3, startCol + 14);

    toggleAlive(startRow + 4, startCol + 1);
    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 4, startCol + 13);
    toggleAlive(startRow + 4, startCol + 14);

    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 1);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 5, startCol + 13);
    toggleAlive(startRow + 5, startCol + 14);
    toggleAlive(startRow + 5, startCol + 15);

    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 1);
    toggleAlive(startRow + 6, startCol + 14);
    toggleAlive(startRow + 6, startCol + 15);

    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 1);
    toggleAlive(startRow + 7, startCol + 15);
    toggleAlive(startRow + 7, startCol + 16);

    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 1);
    toggleAlive(startRow + 8, startCol + 15);
    toggleAlive(startRow + 8, startCol + 16);

    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 1);
    toggleAlive(startRow + 9, startCol + 15);
    toggleAlive(startRow + 9, startCol + 16);

    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 1);
    toggleAlive(startRow + 10, startCol + 15);
    toggleAlive(startRow + 10, startCol + 16);

    toggleAlive(startRow + 11, startCol + 1);
    toggleAlive(startRow + 11, startCol + 2);
    toggleAlive(startRow + 11, startCol + 14);
    toggleAlive(startRow + 11, startCol + 15);

    toggleAlive(startRow + 12, startCol + 1);
    toggleAlive(startRow + 12, startCol + 2);
    toggleAlive(startRow + 12, startCol + 14);
    toggleAlive(startRow + 12, startCol + 15);

    toggleAlive(startRow + 13, startCol + 2);
    toggleAlive(startRow + 13, startCol + 3);
    toggleAlive(startRow + 13, startCol + 4);
    toggleAlive(startRow + 13, startCol + 12);
    toggleAlive(startRow + 13, startCol + 13);
    toggleAlive(startRow + 13, startCol + 14);

    toggleAlive(startRow + 14, startCol + 3);
    toggleAlive(startRow + 14, startCol + 4);
    toggleAlive(startRow + 14, startCol + 5);
    toggleAlive(startRow + 14, startCol + 6);
    toggleAlive(startRow + 14, startCol + 10);
    toggleAlive(startRow + 14, startCol + 11);
    toggleAlive(startRow + 14, startCol + 12);
    toggleAlive(startRow + 14, startCol + 13);

    toggleAlive(startRow + 15, startCol + 5);
    toggleAlive(startRow + 15, startCol + 6);
    toggleAlive(startRow + 15, startCol + 7);
    toggleAlive(startRow + 15, startCol + 8);
    toggleAlive(startRow + 15, startCol + 9);
    toggleAlive(startRow + 15, startCol + 10);
    toggleAlive(startRow + 15, startCol + 11);
    toggleAlive(startRow + 15, startCol + 12);

    toggleAlive(startRow + 16, startCol + 6);
    toggleAlive(startRow + 16, startCol + 7);
    toggleAlive(startRow + 16, startCol + 8);
    toggleAlive(startRow + 16, startCol + 9);
    toggleAlive(startRow + 16, startCol + 10);
    toggleAlive(startRow + 16, startCol + 11);
    toggleAlive(startRow + 16, startCol + 12);
    toggleAlive(startRow + 16, startCol + 13);

    toggleAlive(startRow + 17, startCol + 4);
    toggleAlive(startRow + 17, startCol + 5);
    toggleAlive(startRow + 17, startCol + 6);
    toggleAlive(startRow + 17, startCol + 7);
    toggleAlive(startRow + 17, startCol + 12);
    toggleAlive(startRow + 17, startCol + 13);
    toggleAlive(startRow + 17, startCol + 14);
    toggleAlive(startRow + 17, startCol + 15);

    toggleAlive(startRow + 18, startCol + 3);
    toggleAlive(startRow + 18, startCol + 4);
    toggleAlive(startRow + 18, startCol + 5);
    toggleAlive(startRow + 18, startCol + 14);
    toggleAlive(startRow + 18, startCol + 15);
    toggleAlive(startRow + 18, startCol + 16);

    toggleAlive(startRow + 19, startCol + 3);
    toggleAlive(startRow + 19, startCol + 4);
    toggleAlive(startRow + 19, startCol + 15);
    toggleAlive(startRow + 19, startCol + 16);

    toggleAlive(startRow + 20, startCol + 3);
    toggleAlive(startRow + 20, startCol + 4);
    toggleAlive(startRow + 20, startCol + 16);
    toggleAlive(startRow + 20, startCol + 17);

    toggleAlive(startRow + 21, startCol + 2);
    toggleAlive(startRow + 21, startCol + 3);
    toggleAlive(startRow + 21, startCol + 4);
    toggleAlive(startRow + 21, startCol + 16);
    toggleAlive(startRow + 21, startCol + 17);

    toggleAlive(startRow + 22, startCol + 2);
    toggleAlive(startRow + 22, startCol + 3);
    toggleAlive(startRow + 22, startCol + 16);
    toggleAlive(startRow + 22, startCol + 17);

    toggleAlive(startRow + 23, startCol + 2);
    toggleAlive(startRow + 23, startCol + 3);
    toggleAlive(startRow + 23, startCol + 17);
    toggleAlive(startRow + 23, startCol + 18);

    toggleAlive(startRow + 24, startCol + 3);
    toggleAlive(startRow + 24, startCol + 4);
    toggleAlive(startRow + 24, startCol + 17);
    toggleAlive(startRow + 24, startCol + 18);

    toggleAlive(startRow + 25, startCol + 3);
    toggleAlive(startRow + 25, startCol + 4);
    toggleAlive(startRow + 25, startCol + 5);
    toggleAlive(startRow + 25, startCol + 16);
    toggleAlive(startRow + 25, startCol + 17);
    toggleAlive(startRow + 25, startCol + 18);

    toggleAlive(startRow + 26, startCol + 3);
    toggleAlive(startRow + 26, startCol + 4);
    toggleAlive(startRow + 26, startCol + 5);
    toggleAlive(startRow + 26, startCol + 16);
    toggleAlive(startRow + 26, startCol + 17);
    toggleAlive(startRow + 26, startCol + 18);

    toggleAlive(startRow + 27, startCol + 4);
    toggleAlive(startRow + 27, startCol + 5);
    toggleAlive(startRow + 27, startCol + 16);
    toggleAlive(startRow + 27, startCol + 17);

    toggleAlive(startRow + 28, startCol + 4);
    toggleAlive(startRow + 28, startCol + 5);
    toggleAlive(startRow + 28, startCol + 6);
    toggleAlive(startRow + 28, startCol + 15);
    toggleAlive(startRow + 28, startCol + 16);
    toggleAlive(startRow + 28, startCol + 17);

    toggleAlive(startRow + 29, startCol + 5);
    toggleAlive(startRow + 29, startCol + 6);
    toggleAlive(startRow + 29, startCol + 7);
    toggleAlive(startRow + 29, startCol + 8);
    toggleAlive(startRow + 29, startCol + 13);
    toggleAlive(startRow + 29, startCol + 14);
    toggleAlive(startRow + 29, startCol + 15);
    toggleAlive(startRow + 29, startCol + 16);

    toggleAlive(startRow + 30, startCol + 6);
    toggleAlive(startRow + 30, startCol + 7);
    toggleAlive(startRow + 30, startCol + 8);
    toggleAlive(startRow + 30, startCol + 9);
    toggleAlive(startRow + 30, startCol + 10);
    toggleAlive(startRow + 30, startCol + 11);
    toggleAlive(startRow + 30, startCol + 12);
    toggleAlive(startRow + 30, startCol + 13);
    toggleAlive(startRow + 30, startCol + 14);
    toggleAlive(startRow + 30, startCol + 15);

    toggleAlive(startRow + 31, startCol + 9);
    toggleAlive(startRow + 31, startCol + 10);
    toggleAlive(startRow + 31, startCol + 11);
    toggleAlive(startRow + 31, startCol + 12);
    toggleAlive(startRow + 31, startCol + 13);
    toggleAlive(startRow + 31, startCol + 14);
  };
  const buildZeroBigRotate = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 5);
    toggleAlive(startRow, startCol + 6);
    toggleAlive(startRow, startCol + 7);
    toggleAlive(startRow, startCol + 8);
    toggleAlive(startRow, startCol + 9);
    toggleAlive(startRow, startCol + 10);

    toggleAlive(startRow + 1, startCol + 3);
    toggleAlive(startRow + 1, startCol + 4);
    toggleAlive(startRow + 1, startCol + 5);
    toggleAlive(startRow + 1, startCol + 6);
    toggleAlive(startRow + 1, startCol + 7);
    toggleAlive(startRow + 1, startCol + 8);
    toggleAlive(startRow + 1, startCol + 9);
    toggleAlive(startRow + 1, startCol + 10);
    toggleAlive(startRow + 1, startCol + 11);
    toggleAlive(startRow + 1, startCol + 12);

    toggleAlive(startRow + 2, startCol + 2);
    toggleAlive(startRow + 2, startCol + 3);
    toggleAlive(startRow + 2, startCol + 4);
    toggleAlive(startRow + 2, startCol + 5);
    toggleAlive(startRow + 2, startCol + 10);
    toggleAlive(startRow + 2, startCol + 11);
    toggleAlive(startRow + 2, startCol + 12);
    toggleAlive(startRow + 2, startCol + 13);

    toggleAlive(startRow + 3, startCol + 1);
    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 3, startCol + 3);
    toggleAlive(startRow + 3, startCol + 12);
    toggleAlive(startRow + 3, startCol + 13);
    toggleAlive(startRow + 3, startCol + 14);

    toggleAlive(startRow + 4, startCol + 1);
    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 4, startCol + 13);
    toggleAlive(startRow + 4, startCol + 14);

    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 1);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 5, startCol + 13);
    toggleAlive(startRow + 5, startCol + 14);
    toggleAlive(startRow + 5, startCol + 15);

    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 1);
    toggleAlive(startRow + 6, startCol + 2);
    toggleAlive(startRow + 6, startCol + 13);
    toggleAlive(startRow + 6, startCol + 14);
    toggleAlive(startRow + 6, startCol + 15);

    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 1);
    toggleAlive(startRow + 7, startCol + 14);
    toggleAlive(startRow + 7, startCol + 15);

    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 1);
    toggleAlive(startRow + 8, startCol + 14);
    toggleAlive(startRow + 8, startCol + 15);

    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 1);
    toggleAlive(startRow + 9, startCol + 14);
    toggleAlive(startRow + 9, startCol + 15);

    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 1);
    toggleAlive(startRow + 10, startCol + 15);
    toggleAlive(startRow + 10, startCol + 16);

    toggleAlive(startRow + 11, startCol + 1);
    toggleAlive(startRow + 11, startCol + 2);
    toggleAlive(startRow + 11, startCol + 15);
    toggleAlive(startRow + 11, startCol + 16);

    toggleAlive(startRow + 12, startCol + 1);
    toggleAlive(startRow + 12, startCol + 2);
    toggleAlive(startRow + 12, startCol + 15);
    toggleAlive(startRow + 12, startCol + 16);

    toggleAlive(startRow + 13, startCol + 1);
    toggleAlive(startRow + 13, startCol + 2);
    toggleAlive(startRow + 13, startCol + 15);
    toggleAlive(startRow + 13, startCol + 16);

    toggleAlive(startRow + 14, startCol + 1);
    toggleAlive(startRow + 14, startCol + 2);
    toggleAlive(startRow + 14, startCol + 15);
    toggleAlive(startRow + 14, startCol + 16);

    toggleAlive(startRow + 15, startCol + 1);
    toggleAlive(startRow + 15, startCol + 2);
    toggleAlive(startRow + 15, startCol + 16);
    toggleAlive(startRow + 15, startCol + 17);

    toggleAlive(startRow + 16, startCol + 2);
    toggleAlive(startRow + 16, startCol + 3);
    toggleAlive(startRow + 16, startCol + 16);
    toggleAlive(startRow + 16, startCol + 17);

    toggleAlive(startRow + 17, startCol + 2);
    toggleAlive(startRow + 17, startCol + 3);
    toggleAlive(startRow + 17, startCol + 16);
    toggleAlive(startRow + 17, startCol + 17);

    toggleAlive(startRow + 18, startCol + 2);
    toggleAlive(startRow + 18, startCol + 3);
    toggleAlive(startRow + 18, startCol + 16);
    toggleAlive(startRow + 18, startCol + 17);

    toggleAlive(startRow + 19, startCol + 2);
    toggleAlive(startRow + 19, startCol + 3);
    toggleAlive(startRow + 19, startCol + 16);
    toggleAlive(startRow + 19, startCol + 17);

    toggleAlive(startRow + 20, startCol + 2);
    toggleAlive(startRow + 20, startCol + 3);
    toggleAlive(startRow + 20, startCol + 16);
    toggleAlive(startRow + 20, startCol + 17);

    toggleAlive(startRow + 21, startCol + 2);
    toggleAlive(startRow + 21, startCol + 3);
    toggleAlive(startRow + 21, startCol + 17);
    toggleAlive(startRow + 21, startCol + 18);

    toggleAlive(startRow + 22, startCol + 3);
    toggleAlive(startRow + 22, startCol + 4);
    toggleAlive(startRow + 22, startCol + 17);
    toggleAlive(startRow + 22, startCol + 18);

    toggleAlive(startRow + 23, startCol + 3);
    toggleAlive(startRow + 23, startCol + 4);
    toggleAlive(startRow + 23, startCol + 17);
    toggleAlive(startRow + 23, startCol + 18);

    toggleAlive(startRow + 24, startCol + 3);
    toggleAlive(startRow + 24, startCol + 4);
    toggleAlive(startRow + 24, startCol + 17);
    toggleAlive(startRow + 24, startCol + 18);

    toggleAlive(startRow + 25, startCol + 3);
    toggleAlive(startRow + 25, startCol + 4);
    toggleAlive(startRow + 25, startCol + 5);
    toggleAlive(startRow + 25, startCol + 16);
    toggleAlive(startRow + 25, startCol + 17);
    toggleAlive(startRow + 25, startCol + 18);

    toggleAlive(startRow + 26, startCol + 3);
    toggleAlive(startRow + 26, startCol + 4);
    toggleAlive(startRow + 26, startCol + 5);
    toggleAlive(startRow + 26, startCol + 16);
    toggleAlive(startRow + 26, startCol + 17);
    toggleAlive(startRow + 26, startCol + 18);

    toggleAlive(startRow + 27, startCol + 4);
    toggleAlive(startRow + 27, startCol + 5);
    toggleAlive(startRow + 27, startCol + 16);
    toggleAlive(startRow + 27, startCol + 17);

    toggleAlive(startRow + 28, startCol + 4);
    toggleAlive(startRow + 28, startCol + 5);
    toggleAlive(startRow + 28, startCol + 6);
    toggleAlive(startRow + 28, startCol + 15);
    toggleAlive(startRow + 28, startCol + 16);
    toggleAlive(startRow + 28, startCol + 17);

    toggleAlive(startRow + 29, startCol + 5);
    toggleAlive(startRow + 29, startCol + 6);
    toggleAlive(startRow + 29, startCol + 7);
    toggleAlive(startRow + 29, startCol + 8);
    toggleAlive(startRow + 29, startCol + 13);
    toggleAlive(startRow + 29, startCol + 14);
    toggleAlive(startRow + 29, startCol + 15);
    toggleAlive(startRow + 29, startCol + 16);

    toggleAlive(startRow + 30, startCol + 6);
    toggleAlive(startRow + 30, startCol + 7);
    toggleAlive(startRow + 30, startCol + 8);
    toggleAlive(startRow + 30, startCol + 9);
    toggleAlive(startRow + 30, startCol + 10);
    toggleAlive(startRow + 30, startCol + 11);
    toggleAlive(startRow + 30, startCol + 12);
    toggleAlive(startRow + 30, startCol + 13);
    toggleAlive(startRow + 30, startCol + 14);
    toggleAlive(startRow + 30, startCol + 15);

    toggleAlive(startRow + 31, startCol + 8);
    toggleAlive(startRow + 31, startCol + 9);
    toggleAlive(startRow + 31, startCol + 10);
    toggleAlive(startRow + 31, startCol + 11);
    toggleAlive(startRow + 31, startCol + 12);
    toggleAlive(startRow + 31, startCol + 13);
  };

  const buildEjtSmall = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 1);

    toggleAlive(startRow + 1, startCol);
    toggleAlive(startRow + 1, startCol + 2);
    toggleAlive(startRow + 2, startCol);
    toggleAlive(startRow + 2, startCol + 2);
    toggleAlive(startRow + 3, startCol);
    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 4, startCol);
    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 2);
    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 2);
    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 2);
    
    toggleAlive(startRow + 9, startCol + 1);

    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 11, startCol);
    toggleAlive(startRow + 11, startCol + 2);
    toggleAlive(startRow + 12, startCol);
    toggleAlive(startRow + 12, startCol + 2);
    toggleAlive(startRow + 13, startCol);
    toggleAlive(startRow + 13, startCol + 2);
    toggleAlive(startRow + 14, startCol);
    toggleAlive(startRow + 14, startCol + 2);
    toggleAlive(startRow + 15, startCol);
    toggleAlive(startRow + 15, startCol + 2);
    toggleAlive(startRow + 16, startCol);
    toggleAlive(startRow + 16, startCol + 2);
    toggleAlive(startRow + 17, startCol);
    toggleAlive(startRow + 17, startCol + 2);

    toggleAlive(startRow + 18, startCol + 1);
  };

  const buildZeroSmall = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 1);

    toggleAlive(startRow + 1, startCol);
    toggleAlive(startRow + 1, startCol + 2);
    toggleAlive(startRow + 2, startCol);
    toggleAlive(startRow + 2, startCol + 2);
    toggleAlive(startRow + 3, startCol);
    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 4, startCol);
    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 2);
    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 2);
    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 2);
    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 2);
    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 11, startCol);
    toggleAlive(startRow + 11, startCol + 2);
    toggleAlive(startRow + 12, startCol);
    toggleAlive(startRow + 12, startCol + 2);
    toggleAlive(startRow + 13, startCol);
    toggleAlive(startRow + 13, startCol + 2);
    toggleAlive(startRow + 14, startCol);
    toggleAlive(startRow + 14, startCol + 2);
    toggleAlive(startRow + 15, startCol);
    toggleAlive(startRow + 15, startCol + 2);
    toggleAlive(startRow + 16, startCol);
    toggleAlive(startRow + 16, startCol + 2);
    toggleAlive(startRow + 17, startCol);
    toggleAlive(startRow + 17, startCol + 2);

    toggleAlive(startRow + 18, startCol + 1);
  };


  const buildEjtGiant = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 1);

    toggleAlive(startRow + 1, startCol);
    toggleAlive(startRow + 1, startCol + 2);
    toggleAlive(startRow + 2, startCol);
    toggleAlive(startRow + 2, startCol + 2);
    toggleAlive(startRow + 3, startCol);
    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 4, startCol);
    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 2);

    toggleAlive(startRow + 6, startCol + 1);

    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 2);
    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 2);
    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 2);
    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 11, startCol);
    toggleAlive(startRow + 11, startCol + 2);

    toggleAlive(startRow + 12, startCol + 1);
  };

  const buildZeroGiant = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 1);

    toggleAlive(startRow + 1, startCol);
    toggleAlive(startRow + 1, startCol + 2);
    toggleAlive(startRow + 2, startCol);
    toggleAlive(startRow + 2, startCol + 2);
    toggleAlive(startRow + 3, startCol);
    toggleAlive(startRow + 3, startCol + 2);
    toggleAlive(startRow + 4, startCol);
    toggleAlive(startRow + 4, startCol + 2);
    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 2);
    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 2);
    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 2);
    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 2);
    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 2);
    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 2);
    toggleAlive(startRow + 11, startCol);
    toggleAlive(startRow + 11, startCol + 2);

    toggleAlive(startRow + 12, startCol + 1);
  };

  const buildEjtFat = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 1);
    toggleAlive(startRow, startCol + 2);

    toggleAlive(startRow + 1, startCol);
    toggleAlive(startRow + 1, startCol + 3);
    toggleAlive(startRow + 2, startCol);
    toggleAlive(startRow + 2, startCol + 3);
    toggleAlive(startRow + 3, startCol);
    toggleAlive(startRow + 3, startCol + 3);
    toggleAlive(startRow + 4, startCol);
    toggleAlive(startRow + 4, startCol + 3);
    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 3);

    toggleAlive(startRow + 6, startCol + 1);
    toggleAlive(startRow + 6, startCol + 2);

    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 3);
    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 3);
    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 3);
    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 3);
    toggleAlive(startRow + 11, startCol);
    toggleAlive(startRow + 11, startCol + 3);

    toggleAlive(startRow + 12, startCol + 1);
    toggleAlive(startRow + 12, startCol + 2);
  };

  const buildZeroFat = (startRow, startCol) => {
    toggleAlive(startRow, startCol + 1);
    toggleAlive(startRow, startCol + 2);

    toggleAlive(startRow + 1, startCol);
    toggleAlive(startRow + 1, startCol + 3);
    toggleAlive(startRow + 2, startCol);
    toggleAlive(startRow + 2, startCol + 3);
    toggleAlive(startRow + 3, startCol);
    toggleAlive(startRow + 3, startCol + 3);
    toggleAlive(startRow + 4, startCol);
    toggleAlive(startRow + 4, startCol + 3);
    toggleAlive(startRow + 5, startCol);
    toggleAlive(startRow + 5, startCol + 3);
    toggleAlive(startRow + 6, startCol);
    toggleAlive(startRow + 6, startCol + 3);
    toggleAlive(startRow + 7, startCol);
    toggleAlive(startRow + 7, startCol + 3);
    toggleAlive(startRow + 8, startCol);
    toggleAlive(startRow + 8, startCol + 3);
    toggleAlive(startRow + 9, startCol);
    toggleAlive(startRow + 9, startCol + 3);
    toggleAlive(startRow + 10, startCol);
    toggleAlive(startRow + 10, startCol + 3);
    toggleAlive(startRow + 11, startCol);
    toggleAlive(startRow + 11, startCol + 3);

    toggleAlive(startRow + 12, startCol + 1);
    toggleAlive(startRow + 12, startCol + 2);
  };

  // buildEjtBigSkewed(0, 0);
  // buildZeroBigSkewed(0, 17);
  // buildEjtBigSkewed(0, 34);
  // buildZeroBigSkewed(0, 51);

  const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 1000;

  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1000;

  // buildEjt(parseInt(windowHeight / CELL_HEIGHT / 2) - 7, parseInt(windowWidth / CELL_WIDTH / 2) - 13);
  // buildZero(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 7, parseInt(window.innerWidth / CELL_WIDTH / 2) - 6)
  // buildEjt(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 7, parseInt(window.innerWidth / CELL_WIDTH / 2) + 1)
  // buildZero(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 7, parseInt(window.innerWidth / CELL_WIDTH / 2) + 8)

  // buildEjtBigSkewed(parseInt(windowHeight / CELL_SIZE / 2) - 16, parseInt(windowWidth / CELL_SIZE / 2) - 34);
  // buildZeroBigSkewed(parseInt(window.innerHeight / CELL_SIZE / 2) - 16, parseInt(window.innerWidth / CELL_SIZE / 2) - 16)
  // buildEjtBigSkewed(parseInt(window.innerHeight / CELL_SIZE / 2) - 16, parseInt(window.innerWidth / CELL_SIZE / 2) + 1)
  // buildZeroBigSkewed(parseInt(window.innerHeight / CELL_SIZE / 2) - 16, parseInt(window.innerWidth / CELL_SIZE / 2) + 18)




  // buildEjt(parseInt(windowHeight / CELL_HEIGHT / 2) - 26, parseInt(windowWidth / CELL_WIDTH / 2) - 3);
  // buildZero(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 14, parseInt(window.innerWidth / CELL_WIDTH / 2) - 3)
  // buildEjt(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 2, parseInt(window.innerWidth / CELL_WIDTH / 2) - 3)
  // buildZero(parseInt(window.innerHeight / CELL_HEIGHT / 2) + 11, parseInt(window.innerWidth / CELL_WIDTH / 2) - 3)


  if (isMobile) {
    buildEjtSmall(parseInt(windowHeight / CELL_HEIGHT / 2) - 9, parseInt(windowWidth / CELL_WIDTH / 2) - 6);
    buildZeroSmall(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 9, parseInt(window.innerWidth / CELL_WIDTH / 2) - 2)
    buildEjtSmall(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 9, parseInt(window.innerWidth / CELL_WIDTH / 2) + 2 )
    buildZeroSmall(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 9, parseInt(window.innerWidth / CELL_WIDTH / 2) + 6)

  } else {
    // buildEjtGiant(parseInt(windowHeight / CELL_HEIGHT / 2) - 6, parseInt(windowWidth / CELL_WIDTH / 2) - 8);
    // buildZeroGiant(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 6, parseInt(window.innerWidth / CELL_WIDTH / 2) - 3)
    // buildEjtGiant(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 6, parseInt(window.innerWidth / CELL_WIDTH / 2) + 2)
    // buildZeroGiant(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 6, parseInt(window.innerWidth / CELL_WIDTH / 2) + 7)

    buildEjtFat(parseInt(windowHeight / CELL_HEIGHT / 2) - 6, parseInt(windowWidth / CELL_WIDTH / 2) - 9);
    buildZeroFat(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 6, parseInt(window.innerWidth / CELL_WIDTH / 2) - 4)
    buildEjtFat(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 6, parseInt(window.innerWidth / CELL_WIDTH / 2) + 1)
    buildZeroFat(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 6, parseInt(window.innerWidth / CELL_WIDTH / 2) + 6)

  }



  // buildEjtBigSkewed(0, 0);

  // buildEjtBigRotate(parseInt(windowHeight / CELL_HEIGHT / 2) - 10, parseInt(windowWidth / CELL_WIDTH / 2) - 34);
  // buildZeroBigRotate(
  //   parseInt(window.innerHeight / CELL_HEIGHT / 2) - 14,
  //   parseInt(window.innerWidth / CELL_WIDTH / 2) - 16
  // );
  // buildEjtBigRotate(parseInt(window.innerHeight / CELL_HEIGHT / 2) - 18, parseInt(window.innerWidth / CELL_WIDTH / 2) + 1);
  // buildZeroBigRotate(
  //   parseInt(window.innerHeight / CELL_HEIGHT / 2) - 20,
  //   parseInt(window.innerWidth / CELL_WIDTH / 2) + 18
  // );
};